import React, { useRef, useEffect } from 'react';

const GradientBackground = ({ transitionSpeed = 0.001, children }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const colors = [
      { r: 255, g: 182, b: 193 },  // Light pink
      { r: 173, g: 216, b: 230 },  // Light blue
      { r: 240, g: 230, b: 140 },  // Light yellow
      { r: 144, g: 238, b: 144 }   // Light green
    ];

    let currentColorIndex = 0;
    let nextColorIndex = 1;
    let progress = 0;

    const lerp = (start, end, t) => {
      return start * (1 - t) + end * t;
    };

    const drawGradient = () => {
      const currentColor = colors[currentColorIndex];
      const nextColor = colors[nextColorIndex];
      const r = Math.floor(lerp(currentColor.r, nextColor.r, progress));
      const g = Math.floor(lerp(currentColor.g, nextColor.g, progress));
      const b = Math.floor(lerp(currentColor.b, nextColor.b, progress));

      const diagonalLength = Math.sqrt(canvas.width * canvas.width + canvas.height * canvas.height);
      
      const gradient = ctx.createRadialGradient(
        0, 0, 0,
        0, 0, diagonalLength
      );
      
      gradient.addColorStop(0, `rgb(${r}, ${g}, ${b})`);
      gradient.addColorStop(0.5, `rgb(${g}, ${b}, ${r})`);
      gradient.addColorStop(1, `rgb(${b}, ${r}, ${g})`);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      progress += transitionSpeed;
      if (progress >= 1) {
        progress = 0;
        currentColorIndex = nextColorIndex;
        nextColorIndex = (nextColorIndex + 1) % colors.length;
      }
      requestAnimationFrame(drawGradient);
    };

    drawGradient();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [transitionSpeed]);

  return (
    <div className="relative w-full h-full">
      <canvas
        ref={canvasRef}
        className="absolute top-0 left-0 w-full h-full filter blur-3xl"
      />
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default GradientBackground;
